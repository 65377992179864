<template>
    <html>
      <body class="" style="background: #FFF">
        <HeaderMobil />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col" style="height:auto;">
           <MenuLateral/>
            <div class="row-span-2 col-span-6" style="background:#FFF;padding:20px;">
              <SavingsForMinors/>
            </div>
            <link rel="stylesheet" href="../../node_modules/bootstrap/dist/css/bootstrap.css">
            <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
        </div>
      </body>
    </html>
</template>


<script>
import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue"
import SavingsForMinors from "@/components/Cuenta/SavingsForMinors/SavingsForMinors.vue"
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue"
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
import Footer from '@/components/Footer.vue'
export default {
  data() {
      return {
          errors: [],
          apiToken: null,
        };
      },
  name: 'SavingForMinorsView',
  components: {
    MenuPrincipal,
    SavingsForMinors,
    HeaderMobil,
    MenuLateral,
    MenuLateralMobile,
    Footer
  }
}

</script>