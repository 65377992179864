<template>
    <div class="container">
        <div class="grid grid-cols-1 sm:grid-cols-1">
            <center>
                <p class="RobotoBold text-2xl lg:text-3xl" style="color: #821740;">
                    Inscripción de Ahorro para Menores de Edad
                </p>
            </center>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-1">
            <center>
                <p>
                    
                </p>
            </center>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-4 sm:gap-24 mb-10">

            <div class="mx-5">
                <center>
                    <img src="/images/savings_for_minors/1.svg"/>
                </center>
            </div>

            <div class="mt-10 col-start-2 col-span-4">
                <div class="flex divide-y divide-y-reverse divide-gray-400 pb-5">
                    <p style="color:#feb72b" class="text-lg sm:text-xl font-bold text-slate-500">I. </p>
                    <p style="color:#810042" class="text-lg sm:text-xl font-bold text-slate-500">
                        REQUISITOS DE INSCRIPCIÓN AL AHORRO DEL MENOR DE EDAD
                    </p>
                </div>

                <div class="ml-12">
                    <ol class="list-decimal">
                        <li class="text-sm"> El padre, madre o tutor debe ser Socio Cooperativista Activo</li>
                        <li class="text-sm"> El menor de edad debe tener entre 3 y 17 años de edad</li>
                        <li class="text-sm"> Monto de apertura $25.00 (veinticinco pesos 00/100 m.n)</li>
                        <li class="text-sm"> El ahorro se realizará vía transferencia electrónica o SPEI</li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-4 sm:gap-24 mb-10">

            <div class="mx-5">
                <center>
                    <img src="/images/savings_for_minors/2.svg"/>
                </center>
            </div>

            <div class="mt-10 col-start-2 col-span-4">
                <div class="flex divide-y divide-y-reverse divide-gray-400 pb-5">
                    <p style="color:#feb72b" class="text-lg sm:text-xl font-bold text-slate-500">II. </p>
                    <p style="color:#810042" class="text-lg sm:text-xl font-bold text-slate-500">
                        DOCUMENTACIÓN PARA LA INSCRIPCIÓN AL AHORRO DEL MENOR DE EDAD
                    </p>
                </div>

                <div class="ml-12">
                    <ol class="list-decimal">
                        <li class="text-sm"> Acta de nacimiento del menor de edad</li>
                        <li class="text-sm"> Clave única de registro de poblacion del menor de edad (CURP)</li>
                        <li class="text-sm"> Identificación vigente del padre, madre o tutor del menor de edad (Socio cooperativista activo)</li>
                        <li class="text-sm"> Comprobante de domicilio vigente (no mayor a 3 meses)</li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-1">
            <center>
                <p class="text-2xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-3x1" style="font-family:Roboto;color: #821740; font-weight: bold">
                    Pasos a seguir
                </p>
            </center>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-1 mb-5">
            <center>
                <p>
                    
                </p>
            </center>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-24 mb-10">
            
            <div>
                <div class="border border-slate-300">
                    <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/2">
                        <div>
                            <center>
                                <img src="/images/savings_for_minors/paso1.svg" width="150"/>
                            </center>
                        </div>
                    </figure>
                    <div class="pt-6 text-center space-y-4 mb-2">
                        <figcaption class="text-sm">
                            <div class="text-slate-700 dark:text-slate-500 mx-5">
                                Descargar la solicitud de Ahorro para Menores de Edad dando clic en el siguiente boton.
                            </div>
                        </figcaption>
                    </div>
                    <center>
                        <div class="mb-10">
                            <button id="button_popups" v-on:click="redirectTo('https://cabsacloud.ams3.digitaloceanspaces.com/store/FONCABSA/assets/solicitud_inscripcion_ahorro_menor_edad.pdf')">
                                Descargar Solicitud
                            </button>
                        </div>
                    </center>
                </div>
            </div>
                

            <div>
                <div class="border border-slate-300">
                    <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/2">
                        <div>
                            <center>
                                <img src="/images/savings_for_minors/paso2.svg" width="150"/>
                            </center>
                        </div>
                    </figure>
                    <div class="pt-6 text-center space-y-4 mb-20">
                        <figcaption class="text-sm">
                            <div class="text-slate-700 dark:text-slate-500 mx-20">
                                Imprime la solicitud y llénala a mano con tinta azul.
                            </div>
                        </figcaption>
                    </div>
                </div>
            </div>

            <div>
                <div class="border border-slate-300">
                    <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/2">
                        <div>
                            <center>
                                <img src="/images/savings_for_minors/paso3.svg" width="150"/>
                            </center>
                        </div>
                    </figure>
                    <div class="pt-6 text-center space-y-4 mb-20">
                        <figcaption class="text-sm">
                            <div class="text-slate-700 dark:text-slate-500 mx-5">
                                Escanea la solicitud y enviala al correo de <b>clientes@foncabsa.com</b> con el resto de la documentacion.
                            </div>
                        </figcaption>
                    </div>
                </div>
            </div>

        </div>



        <div class="grid grid-cols-1 sm:grid-cols-1 mt-20">
            <center>
                <p class="text-2xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-3x1" style="font-family:Roboto;color: #821740; font-weight: bold">
                    Retiro Parcial de Ahorro para Menores de Edad
                </p>
            </center>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-1">
            <center>
                <p>
                    
                </p>
            </center>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-4 sm:gap-24 mb-10">
            
            <div class="mx-5">
                <center>
                    <img src="/images/savings_for_minors/3.svg" width="150"/>
                </center>
            </div>

            <div class="mt-10 col-start-2 col-span-4">
                <div class="flex divide-y divide-y-reverse divide-gray-400 pb-5">
                    <p style="color:#feb72b" class="text-lg sm:text-xl font-bold text-slate-500">I. </p>
                    <p style="color:#810042" class="text-lg sm:text-xl font-bold text-slate-500">
                        DOCUMENTACIÓN PARA EL RETIRO PARCIAL DEL AHORRO DEL MENOR DE EDAD
                    </p>
                </div>

                <div class="ml-12">
                    <ol class="list-decimal">
                        <li class="text-sm"> Identificación vigente del padre, madre o tutor socio</li>
                        <li class="text-sm"> Acta de nacimiento del menor de edad ahorrador</li>
                        <li class="text-sm"> Estado de cuenta a nombre del padre, madre o tutor del menor de edad donde se depositarán los recursos (con una antigüedad no mayor a dos meses)</li>
                        <li class="text-sm"> En caso de que el menor de edad ahorrador tenga una cuenta bancaria a su nombre, se podrá recibir el estado de cuenta no mayor a dos meses para que los recursos le sean acreditados directamente el retiro parcial</li>
                    </ol>
                </div>
            </div>

        </div>

        <div class="grid grid-cols-1 sm:grid-cols-1">
            <center>
                <p class="text-2xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-3x1" style="font-family:Roboto;color: #821740; font-weight: bold">
                    Pasos a seguir
                </p>
            </center>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-1 mb-5">
            <center>
                <p>
                    
                </p>
            </center>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-24 mb-10">
            
            <div>
                <div class="border border-slate-300">
                    <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/2">
                        <div>
                            <center>
                                <img src="/images/savings_for_minors/paso1.svg" width="150"/>
                            </center>
                        </div>
                    </figure>
                    <div class="pt-6 text-center space-y-4 mb-2">
                        <figcaption class="text-sm">
                            <div class="text-slate-700 dark:text-slate-500 mx-5">
                                Descargar la solicitud de Retiro Parcial de Ahorro para Menores de Edad dando clic en el siguiente boton.
                            </div>
                        </figcaption>
                    </div>
                    <center>
                        <div class="mb-10">
                            <button id="button_popups" v-on:click="redirectTo('https://cabsacloud.ams3.digitaloceanspaces.com/store/FONCABSA/assets/formato_retiro_parcial_ahorro_menores.pdf')">
                                Descargar Solicitud
                            </button>
                        </div>
                    </center>
                </div>
            </div>
                

            <div>
                <div class="border border-slate-300">
                    <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/2">
                        <div>
                            <center>
                                <img src="/images/savings_for_minors/paso2.svg" width="150"/>
                            </center>
                        </div>
                    </figure>
                    <div class="pt-6 text-center space-y-4 mb-20">
                        <figcaption class="text-sm">
                            <div class="text-slate-700 dark:text-slate-500 mx-20">
                                Imprime la solicitud y llénala a mano con tinta azul.
                            </div>
                        </figcaption>
                    </div>
                </div>
            </div>

            <div>
                <div class="border border-slate-300">
                    <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/2">
                        <div>
                            <center>
                                <img src="/images/savings_for_minors/paso3.svg" width="150"/>
                            </center>
                        </div>
                    </figure>
                    <div class="pt-6 text-center space-y-4 mb-20">
                        <figcaption class="text-sm">
                            <div class="text-slate-700 dark:text-slate-500 mx-5">
                                Escanea la solicitud y enviala al correo de <b>clientes@foncabsa.com</b> con el resto de la documentacion.
                            </div>
                        </figcaption>
                    </div>
                </div>
            </div>

        </div>

        <hr style="border:15px;"><hr style="border:2px;">

        <div class="grid grid-cols-1 sm:grid-cols-1 mt-20">
            <center>
                <p class="text-2xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-3x1" style="font-family:Roboto;color: #821740; font-weight: bold">
                    Retiro Total de Ahorro para Menores de Edad
                </p>
            </center>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-1">
            <center>
                <p>
                    
                </p>
            </center>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-4 sm:gap-24 mb-10">
            
            <div class="mx-5">
                <center>
                    <img src="/images/savings_for_minors/4.svg" width="150"/>
                </center>
            </div>
            
            <div class="mt-10 col-start-2 col-span-4">
                <div class="flex divide-y divide-y-reverse divide-gray-400 pb-5">
                    <p style="color:#feb72b" class="text-lg sm:text-xl font-bold text-slate-500">I. </p>
                    <p style="color:#810042" class="text-lg sm:text-xl font-bold text-slate-500">
                        DOCUMENTACIÓN PARA EL RETIRO TOTAL DEL AHORRO DEL MENOR DE EDAD
                    </p>
                </div>
                <div class="ml-12">
                    <ol class="list-decimal">
                        <li>Identificación oficial que  acredite que el ahorrador menor de edad ha alcanzado la mayoría de edad</li>
                        <li>Estado de cuenta bancario a nombre del solicitante con una vigencia no mayor a tres meses</li>
                    </ol> 
                </div>
            </div>

        </div>


        <div class="grid grid-cols-1 sm:grid-cols-1">
            <center>
                <p class="text-2xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-3x1" style="font-family:Roboto;color: #821740; font-weight: bold">
                    Pasos a seguir
                </p>
            </center>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-1 mb-5">
            <center>
                <p>
                    
                </p>
            </center>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-24 mb-10">
            
            <div>
                <div class="border border-slate-300">
                    <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/2">
                        <div>
                            <center>
                                <img src="/images/savings_for_minors/paso1.svg" width="150"/>
                            </center>
                        </div>
                    </figure>
                    <div class="pt-6 text-center space-y-4 mb-2">
                        <figcaption class="text-sm">
                            <div class="text-slate-700 dark:text-slate-500 mx-5">
                                Descargar la solicitud de Retiro Total de Ahorro para Menores de Edad dando clic en el siguiente boton.
                            </div>
                        </figcaption>
                    </div>
                    <center>
                        <div class="mb-10">
                            <button id="button_popups" v-on:click="redirectTo('https://cabsacloud.ams3.digitaloceanspaces.com/store/FONCABSA/assets/formato_retiro_ahorro_menores.pdf')">
                                Descargar Solicitud
                            </button>
                        </div>
                    </center>
                </div>
            </div>
                

            <div>
                <div class="border border-slate-300">
                    <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/2">
                        <div>
                            <center>
                                <img src="/images/savings_for_minors/paso2.svg" width="150"/>
                            </center>
                        </div>
                    </figure>
                    <div class="pt-6 text-center space-y-4 mb-20">
                        <figcaption class="text-sm">
                            <div class="text-slate-700 dark:text-slate-500 mx-20">
                                Imprime la solicitud y llénala a mano con tinta azul.
                            </div>
                        </figcaption>
                    </div>
                </div>
            </div>

            <div>
                <div class="border border-slate-300">
                    <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/2">
                        <div>
                            <center>
                                <img src="/images/savings_for_minors/paso3.svg" width="150"/>
                            </center>
                        </div>
                    </figure>
                    <div class="pt-6 text-center space-y-4 mb-20">
                        <figcaption class="text-sm">
                            <div class="text-slate-700 dark:text-slate-500 mx-5">
                                Escanea la solicitud y enviala al correo de <b>clientes@foncabsa.com</b> con el resto de la documentacion.
                            </div>
                        </figcaption>
                    </div>
                </div>
            </div>

        </div>

    </div>


</template>

<script>
export default {
  data() {
      return {
          errors: [],
          apiToken: null,
        };
      },
  name: 'SavingForMinorsView',
  methods:{
    redirectTo(url){
        window.open(url, '_blank');
    }
  }
}

</script>

<style>
#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}
</style>